/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import PaginaNuovaAssistenza from './PaginaNuovaAssistenza';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import moment from 'moment';
import $ from 'jquery';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.74vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let colore = props.column.field === 'priorita' ? props.priority?.color ?? 'black' : props.status.color;
    let renderDiv = <div><span style={{ fontSize: '0.85vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}
export default class PaginaSituazioneAssistenza extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.toolbarOptions = ['Add', 'ExcelExport', { text: 'Aggiorna Tabella', prefixIcon: "e-aggiornatabella", id: "aggiornatabella" },'   ', 
                               { template: this.chkFiltroProdToolbar.bind(this) },'   ', { template: this.chkFiltroCompletiToolbar.bind(this) }, 'Search'];
        this.filterSettings = { type: 'CheckBox'}; 
        this.editSettings = { allowAdding: true, mode: 'Normal'};
        this.childGrid = {
            allowSelection: false,
            enableHover: false,
            showColumnMenu: false,
            gridLines: 'Both',
            allowSorting: true,
            enableAltRow: true,
            queryString: 'parent', 
            load() {
                const str = 'id';
                this.parentDetails.parentKeyFieldValue =
                    this.parentDetails.parentRowData[str];
            }, 
            columns: [
                { field: 'id', headerText: 'ID Attività', textAlign: 'Center', width: '7%', headerTemplate: headerTemplate, template: fieldTemplate },
                { field: 'name', headerText: 'Oggetto Attività', headerTemplate: headerTemplate, template: fieldTemplate, textAlign: 'Center' },
                { field: 'description', headerText: 'Descrizione Attività', headerTemplate: headerTemplate, template: fieldTemplate, textAlign: 'Center' },
                { field: 'data_ora_creazione', headerText: 'Data/Ora Creazione', width: '7.5%', headerTemplate: headerTemplate, textAlign: 'Center', template: fieldTemplate, allowFiltering: false },
                { field: 'stato', headerText: 'Stato', headerTemplate: headerTemplate, template: fieldTemplate, textAlign: 'Center', width: '8%' },
                { field: 'priorita', headerText: 'Priorità', headerTemplate: headerTemplate, template: fieldTemplate, textAlign: 'Center', width: '6%' },
            ]
        };
    }
    state = {
        showDialog1: false
    }     
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    toggleBlocking2(show) {
        let container, root;
        if (show) {
            document.getElementById('blockUIGenerico').style.removeProperty('display');
            document.getElementById('spinnerUIGenerico').style.removeProperty('display');
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.render(CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]);
        }
        else {
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.unmount();
            document.getElementById('blockUIGenerico').style.display = 'none';
            document.getElementById('spinnerUIGenerico').style.display = 'none';
        }
    }
    chkFiltroProdToolbar() {
        return (
            <CheckBoxComponent id='chkVediProd' label='Vedi Richieste in Produzione' ref={ chkObj => this.chkInstance1 = chkObj } checked={true} change={this.onChangeChkFiltriStatus.bind(this)}></CheckBoxComponent>
       );           
    }
    chkFiltroCompletiToolbar() {
        return (
            <CheckBoxComponent id='chkVediCompleti' label='Vedi Richieste Completate' ref={ chkObj => this.chkInstance2 = chkObj } checked={false} change={this.onChangeChkFiltriStatus.bind(this)}></CheckBoxComponent>
       );           
    }
    onChangeChkFiltriStatus(args) {
        this.caricaDati(this.chkInstance1.checked, this.chkInstance2.checked);
    }
    onRowDatabound(args) {  
        const filter = args.data.id;
        const childrecord = new DataManager(this.childGrid.dataSource)
                            .executeLocal(new Query().where("parent", "equal", filter, true));
        if(childrecord.length === 0) {
            args.row.querySelector('td').innerHTML=" ";
            args.row.querySelector('td').className = "e-customizedExpandcell";
        }
    }
    onToolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance1.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance1.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance1.csvExport();
                break;
        } 
        if (args.item.id === 'aggiornatabella') {
            this.caricaDati(this.chkInstance1.checked, this.chkInstance2.checked);
        }
        if (args.item.id === 'GridSituazioneRichieste_add') {
            args.cancel = true;  
            this.dialogInstance1.header = `<div><span style="color: #133B95;font-size: '1.3vw'; font-weight: 'bold'">Richiesta Nuova Assistenza</span></div>`;    
            this.dialogInstance1.width = '65%';
            this.dialogInstance1.height = '93%';
            this.dialogInstance1.minHeight = '93%';
            this.dialogInstance1.buttons =   
            [
              { click: () => { 
                  if (CustomJS.isNullEmpty(document.getElementById('oggettoRichiesta').ej2_instances[0].value?.trim())) {
                      document.getElementById('oggettoRichiesta').focus();
                      return;
                  }
                  let priorita = $("input[type='radio'][name='priorita']:checked").val() === 'Altissima' ? 1 : 
                                 $("input[type='radio'][name='priorita']:checked").val() === 'Alta' ? 2 :
                                 $("input[type='radio'][name='priorita']:checked").val() === 'Normale' ? 3 :
                                 $("input[type='radio'][name='priorita']:checked").val() === 'Bassa' ? 4 : '';
                  let oggettoRichiesta = $("input[type='radio'][name='tipoRichiesta']:checked").val() === 'Errore' ? '[BUG] Anomalia su Jellyfish Technology : ' :
                                         $("input[type='radio'][name='tipoRichiesta']:checked").val() === 'ModificaNoEvo' ? '[MODIFICA] Modifica a Jellyfish Technology : ' :
                                         $("input[type='radio'][name='tipoRichiesta']:checked").val() === 'ModificaEvo' ? '[EVOLUTIVA] Modifica a Jellyfish Technology : ' : '';
                  oggettoRichiesta += document.getElementById('oggettoRichiesta').ej2_instances[0].value?.trim();
                  let descrRichiesta = document.getElementById('descrizioneRichiesta').ej2_instances[0].value?.trim().replace(/<.*?>/g, '');
                  let oggettoBody = {
                    name: oggettoRichiesta,
                    description: descrRichiesta,
                    assignees: [ 54104721 ],  //Stefano Giannotta
                    tags: [],
                    status: "RICHIESTA",
                    priority: priorita,
                    due_date: null,
                    due_datetime: false,
                    time_estimate: null,
                    start_date: null,
                    start_date_time: false,
                    notify_all: false,
                    parent: null,
                    links_to: null,
                    custom_fields: []
                  };
                  this.toggleBlocking2(true);
                  CustomJS.callAPI(`${CustomJS.APIClickUpURLBase1.replace('{0}', CustomJS.clickUPListID).replace('{1}', CustomJS.clickUPTeamID)}`, JSON.stringify(oggettoBody), 'POST',
                    'application/json', CustomJS.tokenAPIClickUP, retData => { 
                      document.getElementById('descrizioneRichiesta').querySelectorAll('.e-rte-image.e-imginline').forEach((elm,idx) => {
                        let blob = (/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/).test(elm.getAttribute('src')?.substring(elm.getAttribute('src')?.indexOf(',')+1)) ? CustomJS.b64toBlob(elm.getAttribute('src')?.substring(elm.getAttribute('src')?.indexOf(',')+1)) : (/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/).test(elm.getAttribute('src2')?.substring(elm.getAttribute('src2')?.indexOf(',')+1)) ? CustomJS.b64toBlob(elm.getAttribute('src2')?.substring(elm.getAttribute('src2')?.indexOf(',')+1)) : null;
                        let formData = new FormData();
                        let estensione = elm.getAttribute('src2')?.indexOf('application/pdf') > -1 ? '.pdf' : elm.getAttribute('src')?.indexOf('image/png') > -1 ? '.png' : elm.getAttribute('src')?.indexOf('image/jpeg') > -1 ? '.jpg'
                                          : elm.getAttribute('src')?.indexOf('image/webp') > -1 ? '.jpg' : elm.getAttribute('src')?.indexOf('image/jfif') > -1 ? '.jpg' 
                                          : elm.getAttribute('src2')?.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') > -1 ? '.docx'
                                          : elm.getAttribute('src2')?.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1 ? '.xlsx' 
                                          : elm.getAttribute('src2')?.indexOf('application/msword') > -1 ? '.doc' : elm.getAttribute('src2')?.indexOf('application/msexcel') > -1 ? '.xls' 
                                          : elm.getAttribute('src2')?.indexOf('text/plain') > -1 ? '.txt' : elm.getAttribute('src2')?.indexOf('text/xml') > -1 ? '.xml' : elm.getAttribute('src2')?.indexOf('application/json') > -1 ? '.json'
                                          : elm.getAttribute('src2')?.indexOf('application/x-zip-compressed') > -1 ? '.zip' :  elm.getAttribute('src2')?.indexOf('application/x-compressed') > -1 ? '.rar' : '';
                        formData.append("attachment", blob, `Allegato${(idx+1).toString()}${estensione}`);
                        const query = new URLSearchParams({
                          custom_task_ids: 'true',
                          // team_id: CustomJS.clickUPTeamID
                        }).toString();

                        CustomJS.callAPI(`${CustomJS.APIClickUpURLBase2.replace('{0}', retData.id).replace('{1}', query)}`, formData, 'POST',
                          'multipart/form-data', CustomJS.tokenAPIClickUP, retData2 => {  
                            console.log(`Allegato caricato correttamente su ClickUp con la seguente risposta : ${retData2}`);
                            console.log(retData2);
                        }, error => {
                          let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                          toast.warn(messaggio, {
                              containerId: 'toastContainer1',
                              onClose: () => toast.dismiss(),                    
                              position: "bottom-right",
                              autoClose: 15000,
                              hideProgressBar: false,            
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: false,
                              progress: undefined,
                              newestOnTop: true,
                              rtl: false,
                              pauseOnFocusLoss: true});
                        }, true, false);          
                      });     
                      this.toggleBlocking2(false);
                      this.dialogInstance1.hide();
                      toast.success('Richiesta di assistenza inviata con successo!!! Il riscontro verrà fornito nel più breve tempo possibile. Grazie', {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 7000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                      );
                      this.caricaDati();
                  }, error => {
                      let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                      toast.warn(messaggio, {
                          containerId: 'toastContainer1',
                          onClose: () => toast.dismiss(),                    
                          position: "bottom-right",
                          autoClose: 15000,
                          hideProgressBar: false,            
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: false,
                          progress: undefined,
                          newestOnTop: true,
                          rtl: false,
                          pauseOnFocusLoss: true});
                  }, true, false);  
                }, 
                buttonModel: { 
                    content: 'INVIA RICHIESTA', 
                    cssClass: 'e-primary',
                    isPrimary: true 
                }
              },
              {
                  click: () => {
                      this.dialogInstance1.hide();
                  },
                  buttonModel: {
                      content: 'ANNULLA',
                  }
              }
          ];  
        this.dialogInstance1.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogNuovaAssistContent');
                const root = createRoot(container); 
                root.unmount();
            };
            this.dialogInstance1.open = (args) => {                
                const container = document.getElementById('defaultDialogNuovaAssistContent');
                const root = createRoot(container);                         
                root.render(<CallbackWrapper callback={() => {
                }}><PaginaNuovaAssistenza key={this.childKey}></PaginaNuovaAssistenza></CallbackWrapper>);
            };  
            this.dialogInstance1.show();               
        }        
    }

    render() {
        return(<React.Fragment>
            <BlockUi id='blockUIPaginaAssistenza' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialog14" showCloseIcon={true} target='body' closeOnEscape={true} animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialogObj => this.dialogInstance1 = dialogObj} isModal={true} style={{ marginTop: '40px', marginLeft: '50px' }}>
                    <div id='defaultDialogNuovaAssistContent'></div>
                </DialogComponent>
                <div className='container-fluid'> 
                    <div className='row'>  
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'> 
                            <GridComponent id="GridSituazioneRichieste" ref={ grid => this.gridInstance1 = grid } toolbar={this.toolbarOptions} toolbarClick={this.onToolbarClick.bind(this)} 
                                allowSelection={false} enableHover={false} showColumnMenu={false} gridLines='Both' allowExcelExport={true} height={this.props.arrivaDa === 'NavMenu' ? '600px' : '500px'} filterSettings={this.filterSettings} 
                                allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={true} allowGrouping={true} enableAltRow={true} editSettings={this.editSettings} 
                                rowDataBound={this.onRowDatabound} pageSettings={{pageSize : 1000, pageCount : 1, pageSizes: []}} childGrid={this.childGrid} hierarchyPrintMode='All'>
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID Richiesta' headerTemplate={headerTemplate} width='8%' textAlign='Center' template={fieldTemplate} clipMode="EllipsisWithTooltip" allowFiltering={false}></ColumnDirective>
                                    <ColumnDirective field='name' headerText="Oggetto Richiesta"  headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate} allowFiltering={false}></ColumnDirective>
                                    <ColumnDirective field='description' headerText="Descrizione Richiesta" headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate} clipMode="EllipsisWithTooltip" allowFiltering={false}></ColumnDirective>
                                    <ColumnDirective field='data_ora_creazione' headerText="Data/Ora Creazione" width='7%' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate} clipMode="EllipsisWithTooltip" allowFiltering={false}></ColumnDirective>
                                    <ColumnDirective field='stato' headerText="Stato" width='9%' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='priorita' headerText="Priorità" width='6%' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page, DetailRow]} />
                            </GridComponent> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){    
        this.caricaDati();
    }
    caricaDati(vediProd, vediCompleti) {
        const query = new URLSearchParams({
            //archived: false,
            // include_markdown_description: 'true',
            // page: '0',
            order_by: 'created',
            reverse: true,
            subtasks: true,
            //statuses: '',
            include_closed: vediCompleti ?? false,
            // assignees: 'string',
            // tags: 'string',
            // due_date_gt: '0',
            // due_date_lt: '0',
            // date_created_gt: '0',
            // date_created_lt: '0',
            // date_updated_gt: '0',
            // date_updated_lt: '0',
            // date_done_gt: '0',
            // date_done_lt: '0',
            // custom_fields: 'string',
            // custom_items: '0'
        }).toString();

        this.toggleBlocking();
        CustomJS.callAPI(`${CustomJS.APIClickUpURLBase3.replace('{0}', CustomJS.clickUPListID).replace('{1}', query)}`, '', 'GET',
          '', CustomJS.tokenAPIClickUP, retData => {  
            console.log(retData);
            this.toggleBlocking();
            let dsTasks = (vediProd ?? true) === true ? _.sortBy(_.filter(retData.tasks, elm => { return CustomJS.isNullEmpty(elm.parent) }), 'date_created').reverse()
                           : _.sortBy(_.filter(retData.tasks, elm => { return CustomJS.isNullEmpty(elm.parent) && elm.status.status.toLowerCase() !== 'in produzione' }), 'date_created').reverse();
            dsTasks.forEach(elm => {
                elm.description = elm.description?.length > 1000 ? `${elm.description.substring(0, 1000)}.....` : elm.description;
                elm.stato = (elm.status.status[0] + elm.status.status.slice(1)).toUpperCase();
                elm.priorita = CustomJS.isNullEmpty(elm.priority?.priority) ? 'N/A' : elm.priority?.priority === 'normal' ? 'Normale' : 
                               elm.priority?.priority === 'high' ? 'Alta' : elm.priority?.priority === 'urgent' ? 'Urgente'
                               : elm.priority?.priority === 'low' ? 'Bassa' : '';
                elm.data_ora_creazione = moment(new Date(`/Date(${elm.date_created})/`.match(/\d+/)[0] * 1)).format('DD/MM/YYYY HH:mm');
            });
            let dsSubTasks = (vediProd ?? true) === true ? _.filter(retData.tasks, elm => { return !CustomJS.isNullEmpty(elm.parent) })
                             : _.filter(retData.tasks, elm => { return !CustomJS.isNullEmpty(elm.parent) && elm.status.status.toLowerCase() !== 'in produzione' });
            dsSubTasks.forEach(elm => {
                elm.description = elm.description?.length > 1000 ? `${elm.description.substring(0, 1000)}.....` : elm.description;
                elm.stato = (elm.status.status[0] + elm.status.status.slice(1)).toUpperCase();
                elm.priorita = CustomJS.isNullEmpty(elm.priority?.priority) ? 'N/A' : elm.priority?.priority === 'normal' ? 'Normale'
                               : elm.priority?.priority === 'high' ? 'Alta' : elm.priority?.priority === 'urgent' ? 'Urgente'
                               : elm.priority?.priority === 'low' ? 'Bassa' : '';
                elm.data_ora_creazione = moment(new Date(`/Date(${elm.date_created})/`.match(/\d+/)[0] * 1)).format('DD/MM/YYYY HH:mm');
            });
            this.gridInstance1.dataSource = dsTasks;
            this.gridInstance1.childGrid.dataSource = dsSubTasks;  
        }, error => {
            let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
        }, true, false);  
    }
}