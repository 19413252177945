/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, Page, Filter, Inject, Sort, Group } from '@syncfusion/ej2-react-grids';
import LogoACT from '../assets/LogoAllCTechnology.jpg';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

// eslint-disable-next-line no-extend-native
Array.prototype.sum1 = function (prop) {
    let total = 0
    for ( let i = 0, _len = this.length; i < _len; i++ ) {
        total += this[i][prop];
    }
    return total;
}

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.85vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let colore = props.column.field === 'Utente' || props.column.field === 'Compagnia' ? '#133B95' : 'purple';
    let renderDiv = <div><span style={{ fontSize: '0.75vw', color: colore, fontWeight: 'bold' }}>{props[props.column.field]}</span></div>;

    return (
        renderDiv
    );
}

export default class Home extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);        
        this.LogoSocieta = LogoACT;   
        this.accordHeader = ['STATISTICHE GENERALI', 'STATISTICHE INDIVIDUALI', 'STATISTICHE COMPAGNIE'];   
        this.toolbarOptions = ['ExcelExport'];
        this.pageSettings = {pageSize : 15, pageCount : 15}; 
    }
    state = {   
        blocking: false,
        progress: 0,
        divSinistriApertiAdOggi: <div></div>, 
        divSinistriAncoraAperti: <div></div>, 
        divSinistriChiusiAnno: <div></div>, 
        divSinistriChiusiMese: <div></div>,
        divSinistriApertiMese: <div></div>, 
        divPercentRestAnnoCorr: <div></div>,
        divLiquidatoMedioMese: <div></div>,
        divLiquidatoMedioAnno: <div></div>,
        divSinistriNoFEMese: <div></div>,
        divSinistriNoFEAnno: <div></div>,
        divTempoMedioSinAnnoCorrGL: <div></div>, 
        divTempoMedioSinAnnoCorr: <div></div>,
        GridData1: [],
        GridData2: [],
        GridData3: [],
        GridData4: [],
        GridData5: [],
        GridData6: [],
        GridData7: []
    }
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }   
    onQueryCellInfo1(args) {
        if (args.column.field === 'PercChiusiPD') {
            if (args.data.PercChiusiPD.replace('%','').replace(',','.') >= 80 )
                args.cell.bgColor = "LightGreen";
            else 
                args.cell.bgColor = "LightCoral";
        }
        if (args.column.field === 'LiquidatoMedioChiusiPD') {
            if ((args.data.IdCompagnia === 5 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') < 700)
                 || (args.data.IdCompagnia === 3 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') < 600)
                 || (args.data.IdCompagnia === 6 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') < 900))
                args.cell.bgColor = "LightGreen";
            else if ((args.data.IdCompagnia === 5 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') >= 700 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') <= 799)
                      || (args.data.IdCompagnia === 3 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') >= 600 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') <= 699)
                      || (args.data.IdCompagnia === 6 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') >= 900 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') <= 999)) 
                args.cell.bgColor = "Gold";
            else if (args.data.IdCompagnia === 5 || args.data.IdCompagnia === 3 || args.data.IdCompagnia === 6)
                args.cell.bgColor = "LightCoral";

            if (args.data.IdCompagnia !== 5 && args.data.IdCompagnia !== 3 && args.data.IdCompagnia !== 6) {
                if (args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') < 700)
                    args.cell.bgColor = "LightGreen";
                else if (args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') >= 700 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') <= 799)
                    args.cell.bgColor = "Gold";
                else 
                    args.cell.bgColor = "LightCoral";
            }
        }
        if (args.column.field === 'PercChiusiNegativiPD') {
            if (args.data.PercChiusiNegativiPD.replace('%','').replace(',','.') >= 10 )
                args.cell.bgColor = "LightGreen";
            else 
                args.cell.bgColor = "LightCoral";
        }
    }
    onDataBound1(args) {
        document.querySelectorAll('[id^="GridStatPrestAnnoCorrcaptioncell"]').forEach(elm => {
            elm.style.fontWeight = 500;
            elm.style.fontSize = '18px';
        });
    }
    accordContent1() {
        return (<div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">      
                                        <div className='row'></div>
                                        <div className="row card-layout">
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Sinistri ricevuti ad oggi</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divSinistriApertiAdOggi}
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Sinistri ancora aperti</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divSinistriAncoraAperti}
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Sinistri chiusi nell'anno</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divSinistriChiusiAnno}
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Sinistri chiusi nel mese</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divSinistriChiusiMese}
                                                    </div>  
                                                </div>  
                                            </div>
                                        </div>  
                                        <div className="row card-layout">
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Sinistri ricevuti nel mese</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divSinistriApertiMese}
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Percentuale rest. {new Date().getFullYear().toString()}</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divPercentRestAnnoCorr}
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Sinistri No FE mese</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divSinistriNoFEMese}
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Sinistri No FE anno</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divSinistriNoFEAnno}
                                                    </div>  
                                                </div>  
                                            </div>             
                                        </div>    
                                        <div className="row card-layout">                                                                           
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Tempo medio sinistri {new Date().getFullYear().toString()} (solo g.l.)</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divTempoMedioSinAnnoCorrGL}
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Tempo medio sinistri {new Date().getFullYear().toString()}</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divTempoMedioSinAnnoCorr}
                                                    </div>  
                                                </div>  
                                            </div>
                                            {/* <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Liquidato medio mese</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divLiquidatoMedioMese}
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: '500', color:'#133B95'}}>Liquidato medio anno</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        {this.state.divLiquidatoMedioAnno}
                                                    </div>  
                                                </div>  
                                            </div> */}
                                        </div>    
                                    </div>   
                                </div>  
                            </div>                                                          
                        </div>
                    </div>
                </div>);  
    }
    accordContent2() {
        return (<div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">      
                                        <div className='row'></div>
                                        <div className="row card-layout">
                                            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>Statistiche individuali percent. restituzioni {(new Date().getFullYear()-1).toString()}</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        <GridComponent id="GridStatPercRestAnnoPrec" dataSource={this.state.GridData1} ref={ grid => this.gridInstance1 = grid } toolbar={this.toolbarOptions} toolbarClick={this.toolbarClick1.bind(this)} 
                                                            allowSelection={false} enableHover={false} allowExcelExport={true} allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}
                                                            pageSettings={this.pageSettings}>
                                                            <ColumnsDirective>
                                                                <ColumnDirective field='Utente' headerText='Utente' headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='Totali' headerText="Totali" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='Chiusi' headerText="Chiusi" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='Scaricati' headerText="Scaricati" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                            </ColumnsDirective>
                                                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]} />
                                                        </GridComponent>  
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>Statistiche individuali percent. restituzioni {(new Date().getFullYear()).toString()}</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        <GridComponent id="GridStatPercRestAnnoCorr" dataSource={this.state.GridData2} ref={ grid => this.gridInstance2 = grid } toolbar={this.toolbarOptions} toolbarClick={this.toolbarClick2.bind(this)} 
                                                            allowSelection={false} enableHover={false} allowExcelExport={true} allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}
                                                            pageSettings={this.pageSettings}>
                                                            <ColumnsDirective>
                                                            <ColumnDirective field='Utente' headerText='Utente' headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='Totali' headerText="Totali" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='Chiusi' headerText="Chiusi" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='Scaricati' headerText="Scaricati" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                            </ColumnsDirective>
                                                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]} />
                                                        </GridComponent>  
                                                    </div>  
                                                </div>  
                                            </div> 
                                        </div>     
                                        <div className='row'></div>
                                        <div className="row card-layout">
                                            <div className="col-xs-12 col-sm-8 offset-sm-2 col-lg-8 offset-lg-2 col-md-8 offset-md-2">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>Statistiche prestazioni individuali anno {(new Date().getFullYear()).toString()}</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        <GridComponent id="GridStatPrestAnnoCorr" dataSource={this.state.GridData7} ref={ grid => this.gridInstance7 = grid } toolbar={this.toolbarOptions} toolbarClick={this.toolbarClick1.bind(this)} 
                                                            allowSelection={false} enableHover={false} allowExcelExport={true} allowTextWrap={true} allowPaging={false} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}
                                                            queryCellInfo={this.onQueryCellInfo1.bind(this)} dataBound={this.onDataBound1.bind(this)} allowGrouping={true} groupSettings={{ columns: ['Compagnia'], showGroupedColumn: false }} pageSettings={this.pageSettings}>
                                                            <ColumnsDirective>
                                                                <ColumnDirective field='Compagnia' visible={false}></ColumnDirective>
                                                                <ColumnDirective field='Utente' headerText='Utente' headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='TotaleChiusi' headerText="Incarichi Chiusi Totali" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='TotaleChiusiPD' headerText="Incarichi Chiusi in PD" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='PercChiusiPD' headerText="Percentuale Incarichi chiusi in PD" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='LiquidatoMedioChiusiPD' headerText="Liquidato Medio Incarichi chiusi in PD" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='ChiusiNegativiPD' headerText="Chiusure Negative Incarichi in PD" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='PercChiusiNegativiPD' headerText="Percentuale Chiusure Negative Incarichi in PD" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                            </ColumnsDirective>
                                                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page]} />
                                                        </GridComponent>  
                                                    </div>  
                                                </div>  
                                            </div>
                                        </div>      
                                    </div>   
                                </div>  
                            </div>                                                          
                        </div>
                    </div>
                </div>);  
    }
    accordContent3() {
        return (<div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">      
                                        <div className='row'></div>                                        
                                        <div className="row card-layout">
                                            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>Statistiche compagnie percent. restituzioni {(new Date().getFullYear()).toString()}</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        <GridComponent id="GridStatCompPercRestAnnoCorr" dataSource={this.state.GridData3} ref={ grid => this.gridInstance3 = grid } toolbar={this.toolbarOptions} toolbarClick={this.toolbarClick1.bind(this)} 
                                                            allowSelection={false} enableHover={false} allowExcelExport={true} allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}
                                                            pageSettings={this.pageSettings}>
                                                            <ColumnsDirective>
                                                                <ColumnDirective field='Compagnia' headerText='Compagnia' headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='Totali' headerText="Totali" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='Chiusi' headerText="Chiusi" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='Scaricati' headerText="Scaricati" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                            </ColumnsDirective>
                                                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]} />
                                                        </GridComponent>  
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>Statistiche tempo medio compagnie</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        <GridComponent id="GridStatTempoMedioComp" dataSource={this.state.GridData4} ref={ grid => this.gridInstance4 = grid } toolbar={this.toolbarOptions} toolbarClick={this.toolbarClick2.bind(this)} 
                                                            allowSelection={false} enableHover={false} allowExcelExport={true} allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}
                                                            pageSettings={this.pageSettings}>
                                                            <ColumnsDirective>
                                                                <ColumnDirective field='Compagnia' headerText="Compagnia" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='MediaGL' headerText="Media (gl)" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='MediaNoGL' headerText="Media (no gl)" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                            </ColumnsDirective>
                                                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]} />
                                                        </GridComponent>  
                                                    </div>  
                                                </div>  
                                            </div> 
                                        </div>    
                                        <div className="row card-layout">
                                            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>Liquidato mese Compagnia</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        <GridComponent id="GridStatLiquidMedioComp" dataSource={this.state.GridData5} ref={ grid => this.gridInstance5 = grid } toolbar={this.toolbarOptions} toolbarClick={this.toolbarClick1.bind(this)} 
                                                            allowSelection={false} enableHover={false} allowExcelExport={true} allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}
                                                            pageSettings={this.pageSettings}>
                                                            <ColumnsDirective>
                                                                <ColumnDirective field='Compagnia' headerText='Compagnia' headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='MediaLiquidatoComplessivo' width='34%' headerText="Media Liquidato Complessivo (da 1 a 3000 Euro)" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='MediaLiquidato' width='22%' headerText="Media Liquidato (da 250 a 3000 Euro)" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='MediaLiquidatoGenerali' width='22%' headerText="Media Liquidato (da 1 a 10000 Euro)" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                            </ColumnsDirective>
                                                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]} />
                                                        </GridComponent>  
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">
                                                    <div className="e-card-header">
                                                        <div align="center" className="e-card-header-caption">
                                                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>Liquidato anno Compagnia</span>
                                                        </div>
                                                    </div>
                                                    <div className="e-card-content">
                                                        <GridComponent id="GridStatLiquidAnnoComp" dataSource={this.state.GridData6} ref={ grid => this.gridInstance6 = grid } toolbar={this.toolbarOptions} toolbarClick={this.toolbarClick2.bind(this)} 
                                                            allowSelection={false} enableHover={false} allowExcelExport={true} allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}
                                                            pageSettings={this.pageSettings}>
                                                            <ColumnsDirective>
                                                                <ColumnDirective field='Compagnia' headerText='Compagnia' headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='MediaLiquidatoComplessivo' width='34%' headerText="Media Liquidato Complessivo (da 1 a 3000 Euro)" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='MediaLiquidato' width='22%' headerText="Media Liquidato (da 250 a 3000 Euro)" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                <ColumnDirective field='MediaLiquidatoGenerali' width='22%' headerText="Media Liquidato (da 1 a 10000 Euro)" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                            </ColumnsDirective>
                                                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]} />
                                                        </GridComponent>  
                                                    </div>  
                                                </div>  
                                            </div> 
                                        </div>   
                                    </div>   
                                </div>  
                            </div>                                                          
                        </div>
                    </div>
                </div>);  
    }
    toolbarClick1(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance1.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance1.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance1.csvExport();
                break;
        }
    }
    toolbarClick2(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance2.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance2.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance2.csvExport();
                break;
        }
    }

    render () {        
        return (<React.Fragment>  
                    <div className="container-fluid">
                        <BlockUi id='blockUIPaginaHome' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                            <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                            <div className="control-section card-control-section basic_card_layout">
                                <div className="e-card-resize-container">
                                    <div className="card-layout">
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                <div className="e-card-content" style={{ marginLeft: '20px'}}>
                                                    <div className='row'></div>
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-6 col-lg-4 col-md-4">
                                                            <img id='imgLogo' src={this.LogoSocieta} style={{ paddingTop: '20px', paddingLeft: '40px' }} width='100%' height='auto'></img>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 col-lg-3 col-md-3 mt-5 ms-5">
                                                            <span style={{ fontSize: '2.1vw', fontWeight: 'bold', color:'#133B95'}}>Riepilogo Attività</span>
                                                        </div>
                                                        <div id='divProgressBar' className='row' style={{ display: 'none' }}>
                                                            <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8 ms-4 mt-3">
                                                                <Progress theme={{  
                                                                    default: {
                                                                        symbol: this.state.progress + '%',
                                                                        trailColor: 'lightblue',
                                                                        color: 'blue'
                                                                    },
                                                                    active: {
                                                                        symbol: this.state.progress + '%',
                                                                        trailColor: 'yellow',
                                                                        color: 'orange'
                                                                    },
                                                                    success: {
                                                                        trailColor: 'lime',
                                                                        color: 'green'
                                                                    }
                                                                }} percent={this.state.progress}/>
                                                            </div>
                                                        </div>         
                                                    </div> 
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ms-4 me-3">
                                                            <AccordionComponent id='accordionDashboard' expandMode='Single' ref={accord => this.accordInstance = accord}>
                                                                <AccordionItemsDirective>
                                                                    <AccordionItemDirective header={this.accordHeader[0]} expanded={true} content={this.accordContent1.bind(this)} iconCss="e-imageAccordion" />
                                                                    <AccordionItemDirective header={this.accordHeader[1]} expanded={false} content={this.accordContent2.bind(this)} iconCss="e-imageAccordion" />
                                                                    <AccordionItemDirective header={this.accordHeader[2]} expanded={false} content={this.accordContent3.bind(this)} iconCss="e-imageAccordion" />
                                                                </AccordionItemsDirective>
                                                            </AccordionComponent> 
                                                        </div>                                                                                              
                                                    </div>                                                      
                                                </div>   
                                            </div>  
                                        </div>                                                          
                                    </div>
                                </div>
                            </div>                            
                        </BlockUi>     
                    </div>
            </React.Fragment>);
    }
    componentDidMount() {  
        document.getElementById('titoloPagina').innerText = 'Pagina Principale';
        this.caricaDati();  
        if (sessionStorage.getItem('JellyfishTechnologyProgressCaricaTabelle') === '0')       
            document.getElementById('divProgressBar').style.removeProperty('display');  
        this.progressInterval = setInterval(() => {
            this.setState({ progress: sessionStorage.getItem('JellyfishTechnologyProgressCaricaTabelle') });
            if (sessionStorage.getItem('JellyfishTechnologyProgressCaricaTabelle') === '100') {
                setTimeout(() => {
                    document.getElementById('divProgressBar').style.display = 'none'; 
                    console.log(`Percentuale session storage utilizzata : ${CustomJS.getStorageUsagePercentage()}`);
                }, 400);
                clearInterval(this.progressInterval);                
            }
        }, 30);          
    }
    caricaDati() {
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        this.toggleBlocking();
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaDatiDashboard`, '', 'GET',
        '', token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
                return;
            }  
            let gridData1 = [];   
            retData[11].forEach(elm => {
                gridData1.push({ Utente: elm.username, Totali: elm.Totali, Chiusi: elm.Chiusi, Scaricati: `${parseFloat(parseFloat(elm.Chiusi / elm.Totali * 100).toFixed(1)).toLocaleString()}%` });
            });            
            let gridData2 = [];
            retData[12].forEach(elm => {
                gridData2.push({ Utente: elm.username, Totali: elm.Totali, Chiusi: elm.Chiusi, Scaricati: `${parseFloat(parseFloat(elm.Chiusi / elm.Totali * 100).toFixed(1)).toLocaleString()}%` });
            });
            let gridData3 = [];
            retData[13].forEach(elm => {
                gridData3.push({ Compagnia: elm.descrizione, Totali: elm.Totali, Chiusi: elm.Chiusi, Scaricati: `${parseFloat(parseFloat(elm.Chiusi / elm.Totali * 100).toFixed(1)).toLocaleString()}%` });
            });
            let gridData4 = [];
            retData[14].forEach(elm => {
                gridData4.push({ Compagnia: elm.descrizione, MediaGL: elm["Media (gl)"].toLocaleString(), MediaNoGL: elm["Media (no gl)"].toLocaleString() });
            });
            let gridData5 = [];
            retData[15].forEach(elm => {
                gridData5.push({ Compagnia: elm.descrizione, MediaLiquidatoComplessivo: parseFloat(parseFloat(elm.MediaLiquidatoComplessivo ?? 0).toFixed(2)).toLocaleString(), MediaLiquidato: parseFloat(parseFloat(elm.MediaLiquidato ?? 0).toFixed(2)).toLocaleString(), MediaLiquidatoGenerali: parseFloat(parseFloat(elm.MediaLiquidatoGenerali ?? 0).toFixed(2)).toLocaleString() });
            });
            let gridData6 = [];
            retData[16].forEach(elm => {
                gridData6.push({ Compagnia: elm.descrizione, MediaLiquidatoComplessivo: parseFloat(parseFloat(elm.MediaLiquidatoComplessivo ?? 0).toFixed(2)).toLocaleString(), MediaLiquidato: parseFloat(parseFloat(elm.MediaLiquidato ?? 0).toFixed(2)).toLocaleString(), MediaLiquidatoGenerali: parseFloat(parseFloat(elm.MediaLiquidatoGenerali ?? 0).toFixed(2)).toLocaleString() });
            });  
            this.dsGruppo = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00002')))?.map(elm => { return { VALUE: elm.id_compagnia.toString(), TEXT: elm.descrizione} });
            let gridData7 = [];
            let salvaCompagnia = 0, salvaPerito = '', contaSinistriChiusiTot = 0, contaSinistriChiusiTotPD = 0, contaChiusiNegativiPD = 0, pesoTotaleLiquidatoChiusiPD = 0, pesoTotaleLiquidatoChiusiPDUnipolSAI = 0, contaSinistriChiusiTotPDUnipolSAI = 0;
            //console.log(retData[17]);
            retData[17].forEach(elm => {
                if (!CustomJS.isNullEmpty(salvaCompagnia) && !CustomJS.isNullEmpty(salvaPerito) && (salvaCompagnia !== elm.id_compagnia || salvaPerito !== elm.username)) {
                    gridData7.push({ 
                        IdCompagnia: salvaCompagnia,
                        Compagnia: _.find(this.dsGruppo, elm => { return elm.VALUE === salvaCompagnia.toString() })?.TEXT, 
                        Utente: salvaPerito, 
                        TotaleChiusi: parseInt(contaSinistriChiusiTot ?? 0).toLocaleString(),
                        TotaleChiusiPD: parseInt(contaSinistriChiusiTotPD ?? 0).toLocaleString(),
                        PercChiusiPD: `${parseFloat(parseFloat(contaSinistriChiusiTotPD / contaSinistriChiusiTot * 100).toFixed(1)).toLocaleString()}%`,
                        LiquidatoMedioChiusiPD: ((salvaCompagnia !== 3 && contaSinistriChiusiTotPD === 0) || (salvaCompagnia === 3 && contaSinistriChiusiTotPDUnipolSAI === 0)) ? '0' : salvaCompagnia === 3 ? `€  ${parseFloat(parseFloat(pesoTotaleLiquidatoChiusiPDUnipolSAI / contaSinistriChiusiTotPDUnipolSAI).toFixed(2)).toLocaleString()}` : `€  ${parseFloat(parseFloat(pesoTotaleLiquidatoChiusiPD / contaSinistriChiusiTotPD).toFixed(2)).toLocaleString()}`,
                        ChiusiNegativiPD: parseInt(contaChiusiNegativiPD ?? 0).toLocaleString(),
                        PercChiusiNegativiPD: `${contaSinistriChiusiTotPD === 0 ? '0' : parseFloat(parseFloat(contaChiusiNegativiPD / contaSinistriChiusiTotPD * 100).toFixed(1)).toLocaleString()}%`
                    });  
                    contaSinistriChiusiTot = 0; contaSinistriChiusiTotPD = 0; contaChiusiNegativiPD = 0; pesoTotaleLiquidatoChiusiPD = 0; pesoTotaleLiquidatoChiusiPDUnipolSAI = 0; contaSinistriChiusiTotPDUnipolSAI = 0;
                }
                salvaCompagnia = elm.id_compagnia; salvaPerito = elm.username;
                contaSinistriChiusiTot += elm.NumeroSinistri;
                contaSinistriChiusiTotPD += elm.pronta_definizione === true ? elm.NumeroSinistri : 0;
                contaChiusiNegativiPD += (elm.pronta_definizione === true && (elm.id_tipo_indennizzo === 3 || elm.id_tipo_indennizzo === 15 || elm.id_tipo_indennizzo === 20
                                          || elm.id_tipo_indennizzo === 21 || elm.id_tipo_indennizzo === 14 || elm.id_tipo_indennizzo === 22 || elm.id_tipo_indennizzo === 6)) ? elm.NumeroSinistri : 0;
                pesoTotaleLiquidatoChiusiPD += elm.pronta_definizione === true ? (elm.MediaLiquidato * elm.NumeroSinistri) : 0;
                pesoTotaleLiquidatoChiusiPDUnipolSAI += (elm.pronta_definizione === true && elm.id_compagnia === 3 && elm.MediaLiquidato >= 251 && elm.MediaLiquidato <= 5000) ? (elm.MediaLiquidato * elm.NumeroSinistri) : 0;
                contaSinistriChiusiTotPDUnipolSAI += (elm.pronta_definizione === true && elm.id_compagnia === 3 && elm.MediaLiquidato >= 251 && elm.MediaLiquidato <= 5000) ? elm.NumeroSinistri : 0;
            });   
            gridData7.push({ 
                IdCompagnia: salvaCompagnia,
                Compagnia: _.find(this.dsGruppo, elm => { return elm.VALUE === salvaCompagnia.toString() })?.TEXT, 
                Utente: salvaPerito, 
                TotaleChiusi: parseInt(contaSinistriChiusiTot ?? 0).toLocaleString(),
                TotaleChiusiPD: parseInt(contaSinistriChiusiTotPD ?? 0).toLocaleString(),
                PercChiusiPD: parseFloat(parseFloat(contaSinistriChiusiTotPD / contaSinistriChiusiTot * 100).toFixed(1)).toLocaleString(),
                LiquidatoMedioChiusiPD: contaSinistriChiusiTotPD === 0 ? '0' : `€  ${parseFloat(parseFloat(pesoTotaleLiquidatoChiusiPD / contaSinistriChiusiTotPD).toFixed(2)).toLocaleString()}`,
                ChiusiNegativiPD: parseInt(contaChiusiNegativiPD ?? 0).toLocaleString(),
                PercChiusiNegativiPD: contaSinistriChiusiTotPD === 0 ? '0' : parseFloat(parseFloat(contaChiusiNegativiPD / contaSinistriChiusiTotPD * 100).toFixed(1)).toLocaleString()
            });  
            //console.log(gridData7);

            this.setState({ 
                divSinistriApertiAdOggi: <div><span style={{color: '#133B95', fontSize: '1.2vw', fontWeight: '600'}}>Anno Precedente :</span>&nbsp;&nbsp;<span style={{color: 'Purple', fontSize: '1.4vw', fontWeight: 'bold'}}>{retData[0][0].ApertiAnnoPrec.toLocaleString()}</span><br/><br/>
                                         <span style={{color: '#133B95', fontSize: '1.2vw', fontWeight: '600'}}>Anno Corrente :</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color: 'Purple', fontSize: '1.4vw', fontWeight: 'bold'}}>{retData[1][0].ApertiAnnoCorr.toLocaleString()}</span></div>,
                divSinistriAncoraAperti: <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{retData[2][0].AncoraAperti.toLocaleString()}</span></div>,
                divSinistriChiusiAnno: <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{retData[3][0].ChiusiNellAnno.toLocaleString()}</span></div>,
                divSinistriChiusiMese: <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{retData[4][0].ChiusiNelMese.toLocaleString()}</span></div>, 
                divSinistriApertiMese: <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{retData[6][0].ApertiNelMese.toLocaleString()}</span></div>,
                divPercentRestAnnoCorr: <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{parseFloat(parseFloat((retData[5][0].TotChiusi/((retData[5][0].Totali === 0) ? 1 : retData[5][0].Totali)) * 100).toFixed(2)).toLocaleString()}%</span></div>,
                divLiquidatoMedioMese: <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{retData[7].length === 0 ? 0.00 : parseFloat(parseFloat(retData[7].sum1('LiquidatoMedioMese')).toFixed(2)).toLocaleString()}</span></div>,
                divLiquidatoMedioAnno: <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{retData[8].length === 0 ? 0.00 : parseFloat(parseFloat(retData[8].sum1('LiquidatoMedioAnno')/retData[8].length).toFixed(2)).toLocaleString()}</span></div>,
                divSinistriNoFEMese: <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{parseFloat(parseFloat((retData[9][0].TotNoFE/((retData[9][0].TotSinistriMese === 0) ? 1 : retData[9][0].TotSinistriMese)) * 100).toFixed(2)).toLocaleString()}%</span></div>,
                divSinistriNoFEAnno: <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{parseFloat(parseFloat((retData[10][0].TotSinistriAnno === 0 ? 0 : retData[10][0].TotNoFE/retData[10][0].TotSinistriAnno) * 100).toFixed(2)).toLocaleString()}%</span></div>,
                GridData1: gridData1,
                GridData2: gridData2,
                GridData3: gridData3,
                GridData4: gridData4,
                GridData5: gridData5,
                GridData6: gridData6,
                GridData7: gridData7
            });
            CustomJS.getParametroDB(25).then(ret => {
                this.setState({ 
                    divTempoMedioSinAnnoCorrGL : <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{parseFloat(parseFloat(ret).toFixed(2)).toLocaleString()}</span></div>
                });
            }).catch(messaggio => { 
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }); 
            CustomJS.getParametroDB(26).then(ret => {
                this.setState({ 
                    divTempoMedioSinAnnoCorr : <div align="center"><span style={{color: 'Purple', fontSize: '1.8vw', fontWeight: 'bold'}}>{parseFloat(parseFloat(ret).toFixed(2)).toLocaleString()}</span></div> 
                });
            }).catch(messaggio => { 
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }); 
            this.toggleBlocking();
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {                
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);
    }
}
