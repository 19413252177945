/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import * as CustomJS from '../Custom';
import BlockUi from '@availity/block-ui';
import { Layout } from './Layout';
import RegioniProvince1 from '../assets/RegioniProvince1.json';
import RegioniProvince2 from '../assets/RegioniProvince2.json';
import LogoJellyfish from '../assets/JellyfishTechnologyLogoNuovo.png';
import LogoDigitalPraesidium from '../assets/LogoDigitalPraesidium.png';
import AmbienteTest from '../assets/AmbienteTest.png';
import RigaIndirizzoDP from '../assets/RigaIndirizzoDP.png';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { random, isUndefined } from 'underscore';
import $ from 'jquery';
import _ from 'underscore/underscore-min';

const messaggioUtenteNonTrovato = 'Codice Utente e/o Password errati!!';
export default class Login extends Component {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.childKey = 0;
        this.tabList = ['00002', '00012', '02001', '02002', '02004', '02007', '02009', '02011', '02017', '02019', '02020', '02034', '02035', '00003', '00004', '00006', '00007', '00008', '00009', '09001', '09002', '09008', '09009', 'StatoFiltro', 'NoteSinistroModalitaFE', 'NoteOperazioniPeritali',
                        'AgenziaFiltro', 'PolizzaFiltro', 'PolizzaFiltro2', 'CATFiltro', 'PeritoFiltro', 'CQFiltro', 'CRFiltro', 'MetodoFatt', 'PolizzaRifer', 'AssegnaRFD', 'MotivazNO', 'StatoDettaglio', 'AgenziaDettaglio', 'AzioneCosa', 'EmailDefault',
                        'Compagnie_prefissi', 'GEN_partita_ric', 'GEN_partita', 'GEN_garanzia', 'GEN_garanzia_ric', 'GEN_gruppi_gar', 'GEN_forma_ass', 'GEN_formula_ass', 'GEN_stato_rischio', 'UNIPOLSAI_Partite', 'UNI_motivo_rivalsa', '_partite_app', '_garanzie',
                        'Determ_danno', 'UNI_stato_fabbricato', 'GEN_servizio', 'GEN_esito_danno', 'GEN_causale_non_ind', 'GEN_voce', 'GEN_um', 'GEN_CodiciAnia', 'GEN_InformazioniRichiesteSuDanno', 'GEN_RisposteInformazioniRichiesteSuDanno', 'GEN_InformazioniRichiesteRiepilogo',
                        'GEN_RisposteInformazioniRichiesteRiepilogo', '_um_prezziario', 'Prezziario', 'GEN_esito', 'GEN_liquidazione', 'GEN_beneficiario', 'GEN_pagamento', 'UNI_esito_perizia', 'UNI_motivo_mancata_definizione', 'UNI_motivo_negativa_videoper', 'UNI_motivo_interlocutoria', 'Codici_comuni'];
        this.funcList = [];
        this.queryList = [
            {
                NomeTabella: 'StatoFiltro',
                QueryExec: "SELECT [02004].id_tipo_stato, CONCAT([descrizione],' (', [conteggio],')') AS descrizione FROM [02004] INNER JOIN (SELECT [01001].id_stato, Count([01001].id_sinistro) AS conteggio FROM [01001] GROUP BY [01001].id_stato) AS conteggio_stati ON [02004].id_tipo_stato=conteggio_stati.id_stato WHERE [02004].nascosto=0;"
            },
            {
                NomeTabella: 'AgenziaFiltro',
                QueryExec: "SELECT [00004].id_agenzia, CONCAT([ragione_sociale],' (', [conteggio], ')') AS descrizione, [03002].id_divisione FROM [00004] INNER JOIN [03002] ON [00004].id_agenzia=[03002].id_agenzia INNER JOIN (SELECT [01001].id_agenzia, Count([01001].id_sinistro) AS conteggio FROM [01001] GROUP BY [01001].id_agenzia) AS conteggio_agenzie ON [00004].id_agenzia=conteggio_agenzie.id_agenzia ORDER BY CONCAT([ragione_sociale], ' (', [conteggio], ')');"
            },
            {
                NomeTabella: 'PolizzaFiltro',
                QueryExec: "SELECT [02001].id_tipo_polizza, [02001].nome, CONCAT([nome], '  Mod. ', [modello], ' Ed. ', [edizione], ' (', [conteggio], ')') AS descrizione FROM [02001] INNER JOIN (SELECT [01001].id_tipo_polizza, Count([01001].id_sinistro) AS conteggio FROM [01001] GROUP BY [01001].id_tipo_polizza)  AS conteggio_tipi ON [02001].id_tipo_polizza = conteggio_tipi.id_tipo_polizza ORDER BY CONCAT([nome],'   Mod. ', [modello], ' Ed. ', [edizione], ' (', [conteggio], ')');"
            },
            {
                NomeTabella: 'PolizzaFiltro2',
                QueryExec: "SELECT [02001].id_tipo_polizza, [02001].nome, CONCAT([nome], '  Mod. ', [modello], ' Ed. ', [edizione]) AS descrizione FROM [02001] ORDER BY CONCAT([nome],'   Mod. ', [modello], ' Ed. ', [edizione]);"
            },
            {
                NomeTabella: 'CATFiltro',
                QueryExec: "SELECT [00006].id_collaboratore, IIf([ragione_sociale]<>'' And [ragione_sociale] IS NOT NULL,[ragione_sociale], CONCAT([nome], ' ', [cognome])) AS nominativo, [00006].user_sito AS utente_JFCAT, [00006].password_sito AS password_JFCAT FROM [00006] WHERE ((([00006].id_tipo_collaboratore)=2) AND (([00006].attivo)=1)) ORDER BY IIf([ragione_sociale]<>'' And [ragione_sociale] IS NOT NULL,[ragione_sociale], CONCAT([nome], ' ', [cognome]));"
            },
            {
                NomeTabella: 'PeritoFiltro',
                QueryExec: "SELECT [09001].id_utente, CONCAT([09001].nome, ' ', [09001].cognome) AS perito, [09001].email1 FROM [09001] INNER JOIN [09002] ON [09001].id_utente = [09002].id_utente WHERE ((([09002].id_gruppo)=3 Or ([09002].id_gruppo)=7 Or ([09002].id_gruppo)=1) AND (([09001].bloccato)=0) AND (([09002].attivo)=1)) GROUP BY [09001].id_utente, CONCAT([09001].nome, ' ', [09001].cognome), [09001].email1;"
            },
            {
                NomeTabella: 'CQFiltro',
                QueryExec: "SELECT [09001].id_utente, CONCAT([09001].nome, ' ', [09001].cognome) AS CQ FROM [09001] WHERE ((([09001].CQ)=1)) ORDER BY CONCAT([09001].nome, ' ', [09001].cognome);"
            },
            {
                NomeTabella: 'CRFiltro',
                QueryExec: "SELECT [09001].id_utente, CONCAT([09001].nome, ' ', [09001].cognome) AS CR FROM [09001] WHERE ((([09001].CR)=1)) ORDER BY CONCAT([09001].nome, ' ', [09001].cognome);"
            },
            {
                NomeTabella: 'MetodoFatt',
                QueryExec: "SELECT [04006].id_metodo_fatturazione, [04006].descrizione FROM [04006];"
            },
            {
                NomeTabella: 'PolizzaRifer',
                QueryExec: "SELECT [02001].id_tipo_polizza, CONCAT([nome],' ', [modello], ' ', [edizione]) AS descrizione FROM [02001];"
            },
            {
                NomeTabella: 'AssegnaRFD',
                QueryExec: "SELECT [00006].id_collaboratore, IIf([ragione_sociale]<>'' AND [ragione_sociale] IS NOT NULL, [ragione_sociale], CONCAT([nome], ' ', [cognome])) AS nominativo FROM [00006] WHERE ((([00006].id_tipo_collaboratore)=2)) ORDER BY IIf([ragione_sociale]<>'' AND [ragione_sociale] IS NOT NULL, [ragione_sociale], CONCAT([nome], ' ', [cognome]));"
            },
            {
                NomeTabella: 'MotivazNO',
                QueryExec: "SELECT zMotivoNoRipristino.id, zMotivoNoRipristino.descrizione FROM zMotivoNoRipristino; "
            },
            {
                NomeTabella: 'AzioneCosa',
                QueryExec: "SELECT [01006].id_email, [01006].titolo_email, [01006].id_tipo_dest, [020071].id_stato, [01006].id_tipo_messaggio FROM [020071] INNER JOIN [01006] ON [020071].id_email = [01006].id_email  WHERE  [01006].tutti_stati=0   UNION ALL   SELECT [01006].id_email, [01006].titolo_email, [01006].id_tipo_dest, 0, [01006].id_tipo_messaggio  FROM [01006] WHERE [01006].tutti_stati=1  order by titolo_email;"
            },
            {
                NomeTabella: 'StatoDettaglio',
                QueryExec: "SELECT [02004].id_tipo_stato, [02004].descrizione, [02004].t_timer FROM [02004] WHERE [02004].nascosto=0 ORDER BY [02004].descrizione;"
            },
            {
                NomeTabella: 'AgenziaDettaglio',
                QueryExec: "SELECT [00004].id_agenzia, [00004].ragione_sociale, [00004].telefono1, [00004].email1, [00007].descrizione, [03002].id_divisione FROM ([00007] RIGHT JOIN [00004] ON [00007].id_area=[00004].id_area) INNER JOIN [03002] ON [00004].id_agenzia=[03002].id_agenzia ORDER BY [00004].ragione_sociale;"
            },
            {
                NomeTabella: 'EmailDefault',
                QueryExec: "SELECT 'DEF' AS IdEmail, [09005].valore AS ParametriEmail FROM [09005] WHERE [09005].id_parametro = 45;"
            },
            {
                NomeTabella: 'Compagnie_prefissi',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'compagnie_prefissi';"
            },
            {
                NomeTabella: 'GEN_partita_ric',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_partita_ric';"
            },
            {
                NomeTabella: 'GEN_partita',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_partita';"
            },
            {
                NomeTabella: 'GEN_garanzia',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_garanzia';"
            },    
            {
                NomeTabella: 'GEN_garanzia_ric',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_garanzia_ric';"
            },  
            {
                NomeTabella: 'GEN_gruppi_gar',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_gruppi_gar';"
            },  
            {
                NomeTabella: 'GEN_forma_ass',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_forma_ass';"
            },
            {
                NomeTabella: 'GEN_formula_ass',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_formula_ass';"
            }, 
            {
                NomeTabella: 'GEN_stato_rischio',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_stato_rischio';"
            }, 
            {
                NomeTabella: 'UNIPOLSAI_Partite',
                QueryExec: "SELECT * FROM [10005]"
            }, 
            {
                NomeTabella: 'UNI_motivo_rivalsa',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'UNI_motivo_rivalsa'"
            }, 
            {
                NomeTabella: '_partite_app',
                QueryExec: "SELECT * FROM [11015]"
            },
            {
                NomeTabella: '_garanzie',
                QueryExec: "SELECT * FROM [11016]"
            },
            {
                NomeTabella: 'Determ_danno',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'determ_danno';"
            },
            {
                NomeTabella: 'UNI_stato_fabbricato',
                QueryExec: "SELECT * FROM [09011] where COD_TAB = 'UNI_stato_fabbricato'"
            },
            {
                NomeTabella: 'GEN_servizio',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_servizio';"
            },
            {
                NomeTabella: 'GEN_esito_danno',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_esito_danno';"
            },
            {
                NomeTabella: 'GEN_causale_non_ind',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_causale_non_ind';"
            },
            {
                NomeTabella: 'GEN_voce',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_voce';"
            },
            {
                NomeTabella: 'GEN_um',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_um';"
            },
            {
                NomeTabella: 'GEN_CodiciAnia',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'codici_ANIA';"
            },
            {
                NomeTabella: 'GEN_InformazioniRichiesteSuDanno',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_info_rq_danno';"
            },
            {
                NomeTabella: 'GEN_RisposteInformazioniRichiesteSuDanno',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_risp_info_rq_danno';"
            },
            {
                NomeTabella: 'GEN_InformazioniRichiesteRiepilogo',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_info_rq_riep';"
            },
            {
                NomeTabella: 'GEN_RisposteInformazioniRichiesteRiepilogo',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_risp_info_rq_riep';"
            },
            {
                NomeTabella: '_um_prezziario',
                QueryExec: "SELECT distinct servizio_str2 FROM [09011] WHERE COD_TAB = 'prezziario';"
            },
            {
                NomeTabella: 'Prezziario',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'prezziario';"
            },
            {
                NomeTabella: 'GEN_esito',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_esito';"
            },
            {
                NomeTabella: 'GEN_liquidazione',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_liquidazione';"
            },
            {
                NomeTabella: 'GEN_beneficiario',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_beneficiario';"
            },
            {
                NomeTabella: 'GEN_pagamento',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'GEN_pagamento';"
            },
            {
                NomeTabella: 'UNI_esito_perizia',
                QueryExec: "SELECT * FROM [09011] where COD_TAB = 'UNI_esito_perizia'"
            },
            {
                NomeTabella: 'UNI_motivo_mancata_definizione',
                QueryExec: "SELECT * FROM [09011] where COD_TAB = 'UNI_motivo_mancata_definizione'"
            },
            {
                NomeTabella: 'UNI_motivo_negativa_videoper',
                QueryExec: "SELECT * FROM [09011] where COD_TAB = 'UNI_motivo_negativa_videoper'"
            },
            {
                NomeTabella: 'UNI_motivo_interlocutoria',
                QueryExec: "SELECT * FROM [09011] WHERE COD_TAB = 'UNI_motivo_interlocutoria'"
            },
            {
                NomeTabella: 'Codici_comuni',
                QueryExec: "SELECT * FROM [09011] where COD_TAB = 'codici_comuni'"
            },
            {
                NomeTabella: 'NoteSinistroModalitaFE',
                QueryExec: "SELECT * FROM [09011] where COD_TAB = 'diciture_fisse' AND servizio_str2 = 'NSSMFE'"
            },
            {
                NomeTabella: 'NoteOperazioniPeritali',
                QueryExec: "SELECT * FROM [09011] where COD_TAB = 'diciture_fisse' AND servizio_str2 = 'NOPER'"
            },
        ];
    }
    state = {
        blocking: false
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    eseguiLogin() {
        if (ValidateForm()) {
            let dataObj = {
                CodUtente: document.getElementById("UserCode").value.trim(),
                CodAccesso: document.getElementById("Password").value.trim()
            };
            this.toggleBlocking();
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/eseguiLogin`, JSON.stringify(dataObj),
                'POST', 'application/json', '', retData => {
                    if (retData.response === 'OK') {
                        sessionStorage.setItem('JellyfishTechnologyAuthenticationPayload', JSON.stringify(retData));
                        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
                        this.tabList.forEach(elm => {
                            const esegui = () => {
                                return new Promise((resolve, reject) => {
                                    CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, elm, retData => {
                                        if (elm === 'PeritoFiltro') {
                                            retData.unshift({ email1: null, id_utente: 99999, perito: 'Nessun Perito' });
                                        }
                                        if (elm === 'EmailDefault') {
                                            if (!CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].smtp_server?.trim())) {
                                                const parEmail = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].smtp_server?.trim() + ';' +
                                                    JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].smtp_porta?.trim() + ';' +
                                                    JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].smtp_utente?.trim() + ';' +
                                                    JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].smtp_password?.trim() + ';' +
                                                    JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].smtp_ssl + ';' +
                                                    JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].smtp_mittente?.trim() + ';' +
                                                    JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].smtp_email?.trim() + ';' +
                                                    JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].oauth2_client_id?.trim() + ';' +
                                                    JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].oauth2_client_secret?.trim() + ';' +
                                                    JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].oauth2_refresh_token?.trim();
                                                retData.push({ IdEmail: 'PERS', ParametriEmail: parEmail });
                                            }
                                        }
                                        if (retData.length > 0 && elm !== 'EmailDefault') {
                                            let emptyObj = {};
                                            Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
                                            retData.unshift(emptyObj);
                                        }
                                        sessionStorage.setItem(`JellyfishTechnologyTabella${elm}`, CustomJS.compressAndEncode(retData));
                                        resolve("Prossima");
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                        reject(messaggio);
                                    }, _.find(this.queryList, elm2 => { return elm2.NomeTabella === elm })?.QueryExec);
                                });
                            };
                            this.funcList.push(esegui);
                        });
                        this.toggleBlocking();
                        ++this.childKey;
                        $(document).unbind("keypress");
                        document.getElementById('contenutoDinamico3').style.removeProperty('display');
                        document.getElementById('contenutoDinamico1').style.display = 'none';
                        let container = document.getElementById('contenutoDinamico3');
                        let root = createRoot(container);
                        root.render(<CallbackWrapper callback={() => {
                            sessionStorage.setItem("JellyfishTechnologySessionTimeout", 'false');
                            sessionStorage.setItem('JellyfishTechnologyProgressCaricaTabelle', 0);
                            this.caricaTabelle();
                            let arrProv = [];
                            RegioniProvince1.regioni.forEach(elm => {
                                elm.province.forEach((elm2, idx2) => {
                                    arrProv.push({ VALUE: elm2, TEXT: elm.capoluoghi[idx2] });
                                });
                            });
                            sessionStorage.setItem('JellyfishTechnologyTabellaProvince1', CustomJS.compressAndEncode(arrProv));
                            arrProv = [];
                            RegioniProvince2.regioni.forEach(elm => {
                                elm.province.forEach((elm2, idx2) => {
                                    arrProv.push({ VALUE: elm2, TEXT: elm.capoluoghi[idx2] });
                                });
                            });
                            sessionStorage.setItem('JellyfishTechnologyTabellaProvince2', CustomJS.compressAndEncode(arrProv));
                        }}><Layout key={this.childKey}></Layout></CallbackWrapper>);
                    }
                    else if (retData.response === 'KO' && retData.msg === 'UtenteNonTrovato')
                        toast.warn(messaggioUtenteNonTrovato, {
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        })
                    else if (retData.response === 'KO')
                        toast.warn(retData.msg.message, {
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    this.toggleBlocking();
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking();
                }, true, false)
        }
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUILogin' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.4vw', color: 'darkred', fontWeight: 'bold', width: '27vw' }}></ToastContainer>
                <div id="bgImageDiv"></div>
                <div className="container-fluid" >
                    <div className="row"></div>
                    <div className="row">
                        <div className="offset-md-4 col-md-4 offset-lg-4 col-lg-4 offset-sm-2 col-sm-8 offset-xs-1 col-xs-10" >
                            <img src={LogoJellyfish} style={{ maxWidth: '93%', height: 'auto', margin: 'auto' }} />
                        </div>
                    </div>
                    <div className="row" id='container-logo-form'>
                        <div className="offset-md-3 col-md-4 offset-lg-4 col-lg-4 offset-sm-2 col-sm-8 offset-xs-1 col-xs-10">
                            <div className="containerLogin body-content-login">
                                <div id="login-box">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-1">
                                            <h2 style={{ fontWeight: 'bold' }}>LOGIN</h2>
                                        </div>
                                        <div className="offset-lg-5 col-lg-4 offset-md-5 col-md-4 offset-sm-5 col-sm-4 offset-xs-5 col-xs-4 text-end" style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
                                            Rel. 2.1.0
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <h6>Inserire le credenziali d'accesso</h6>
                                    <input type='text' id='UserCode' className='form-login' style={{ width: '350px' }} placeholder='Codice Utente'></input>
                                    <br />
                                    <br />
                                    <br />
                                    <input type='password' id='Password' className='form-login' style={{ width: '350px' }} placeholder='Password'></input>
                                    <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-center">
                                            <input type="button" id="BtnLogin" value="LOGIN" className="btn btn-primary btn-lg btn-default-login" onClick={this.eseguiLogin.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div id="divAmbienteTest" className="row">
                        <div className="offset-md-4 col-md-4 offset-lg-4 col-lg-4 offset-sm-2 col-sm-8 offset-xs-1 col-xs-10">
                            <img src={AmbienteTest} style={{ verticalAlign: 'top', paddingLeft: '100px' }} />
                        </div>
                    </div>    */}
                    <div className="row"></div>
                    <div className="row"></div>
                    <div className="row"></div>
                    <div className="row"></div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-3 col-xs-3">
                            <img src={LogoDigitalPraesidium} style={{ width: '50%', height: 'auto', verticalAlign: 'top' }} />
                        </div>
                        <div className="offset-lg-4 col-lg-4 offset-md-4 col-md-4 offset-sm-6 col-sm-3 offset-xs-6 col-xs-3">
                            <img src={RigaIndirizzoDP} style={{ width: '90%', height: 'auto', paddingTop: '85px' }} />
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        var sessioneScaduta = sessionStorage.getItem("JellyfishTechnologySessionTimeout");
        if (sessioneScaduta === 'true') {
            let messaggio = CustomJS.messaggioSessioneScaduta2;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                toastId: "sessione-scaduta-msg",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            sessionStorage.setItem("JellyfishTechnologySessionTimeout", 'false')
        }
        sessionStorage.setItem("JFTechnologyPaginaLogin", "true");
        $(document).on('keypress',
            event => {
                if (event.key === 'Enter') {
                    this.eseguiLogin();

                }
            });
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
        window.onbeforeunload = function () {
            if (sessionStorage.getItem("JFTechnologyPaginaLogin") === "false")
                return "Attenzione!!! se lasci questa pagina perderai tutte le modifiche fatte e sarai portato alla pagina di Login. Sei sicuro/a di volerlo fare?";
        };
        $(document).on("keydown", disableF5);
        $("#UserCode").focus();
        if (CustomJS.isNullEmpty(sessionStorage.getItem('globalChildKey')))
            sessionStorage.setItem('globalChildKey', 0);
        localStorage.setItem("gridGridAgenzie", "");
        localStorage.setItem("gridGridAree", "");
        localStorage.setItem("gridGridBeni", "");
        localStorage.setItem("gridGridBroker", "");
        localStorage.setItem("gridGridCAT", "");
        localStorage.setItem("gridGridCompagnie", "");
        localStorage.setItem("gridGridDannoAccertato", "");
        localStorage.setItem("gridGridDivisioni", "");
        localStorage.setItem("gridGridGeoreferenziazione", "");
        localStorage.setItem("gridGridSituazioneSinistri", "");
        localStorage.setItem("gridGridLiquidatori", "");
        localStorage.setItem("gridGridResetSinistri", "");
        localStorage.setItem("gridGridTipiDanni", "");
        localStorage.setItem("gridGridTipiPolizze", "");
        localStorage.setItem("gridGridTipiIndennizzi", "");
        localStorage.setItem("gridGridUtenti", "");
        localStorage.setItem("gridGridModelliEmail", "");
        localStorage.setItem("gridGridModelliEmailStati", "");
        sessionStorage.setItem('JellyfishTechnologyFiltriAggiuntiviSinistri', "{}");
    }
    caricaTabelle() {
        const eseguiCiclica = (ind) => {
            if (CustomJS.isNullEmpty(this.funcList[ind])) {
                document.getElementById('sidebar-menu').style.visibility = 'visible';
                toast.dismiss();
                return;
            }
            this.funcList[ind]().then(ret => {
                if (ret === "Prossima") {
                    // console.log(`Caricata tabella ${this.tabList[ind]}`);
                    sessionStorage.setItem('JellyfishTechnologyProgressCaricaTabelle', parseFloat(((ind + 1) / this.tabList.length) * 100).toFixed(0));
                    eseguiCiclica(ind + 1);
                }
            }).catch(err => { console.log(`Errore su caricamento tabella ${this.tabList[ind]} : ${err}`); });
        }
        eseguiCiclica(0);
    }
    componentWillUnmount() {
        // sessionStorage.setItem('JellyfishTechnologyProgressCaricaTabelle', 0);
        // setTimeout(() => {
        //     this.caricaTabelle();
        //     let arrProv = [];
        //     RegioniProvince1.regioni.forEach(elm => {
        //         elm.province.forEach((elm2, idx2) => {
        //             arrProv.push({ VALUE: elm2, TEXT: elm.capoluoghi[idx2] });
        //         });
        //     });
        //     sessionStorage.setItem('JellyfishTechnologyTabellaProvince1', CustomJS.compressAndEncode(arrProv));
        // }, 2200)
    }
}

function ValidateForm() {
    if ($("#UserCode").val().trim() === "") {
        $("#UserCode").attr("placeholder", 'Codice Utente');
        $("#UserCode").css("border-color", "red");
        $("#UserCode").css("border-width", "3px");
        return false;
    }

    if ($("#Password").val().trim() === "") {
        $("#Password").attr("placeholder", 'Password');
        $("#Password").css("border-color", "red");
        $("#UserCode").css("border-width", "3px");
        return false;
    }

    return true;
}

function disableF5(e) {
    if ((e.which || e.keyCode) === 116) e.preventDefault();
};